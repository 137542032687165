import { useFetchClient } from 'utils/src/services/DataFetchClient';
import { theme } from 'twin.macro';
import { BrowserRouter as Router } from 'react-router-dom';
import CompanyPickerProvider from '../hooks/use-company-picker';

// @ts-expect-error javascript module
import Authenticator from './components/Authenticator';
import { useAuthentication } from '@data/auth/transition';

import FetchContext from 'utils/src/context/FetchContext';
import MinScreenProvider from 'utils/src/hooks/use-min-screen';

const Providers = ({ children }: React.PropsWithChildren) => {
  const client = useFetchClient(useAuthentication);

  return (
    <Authenticator>
      <FetchContext.Provider value={client}>
        <MinScreenProvider screens={theme`screens`}>
          <Router>
            <CompanyPickerProvider>{children}</CompanyPickerProvider>
          </Router>
        </MinScreenProvider>
      </FetchContext.Provider>
    </Authenticator>
  );
};

export default Providers;
