import React from 'react';
import * as PrimitiveTooltip from '@radix-ui/react-tooltip';
import Text from './components/text';
import { grayscale } from '@benepass/colors';

const Tooltip = ({ content }: { content: string | React.ReactNode }) => (
  <div className="bg-grayscale-100 p-4 rounded max-w-sm md:max-w-md lg:max-w-2xl">
    {typeof content === 'string' ? <Text className="text-white block">{content}</Text> : content}
  </div>
);

type Props = {
  children?: React.ReactNode;
  content: string | React.ReactNode;
  className?: string;
  margin?: string;
  arrow?: React.ReactNode;
  side?: 'right' | 'bottom';
};

const InfoCircleFilled = ({ className, style }: { className?: string; style?: React.CSSProperties }) => (
  <svg height="1em" width="1em" focusable="false" viewBox="64 64 896 896" className={className} style={style}>
    <path
      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm32 664c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V456c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272zm-32-344a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"
      fill="currentColor"
    />
  </svg>
);

const EnhancedTooltip = ({ children, content, className, margin, arrow, side }: Props): JSX.Element => {
  return (
    <PrimitiveTooltip.Provider>
      <PrimitiveTooltip.Root delayDuration={0}>
        <PrimitiveTooltip.Trigger className={className} asChild>
          {children || (
            <div>
              <InfoCircleFilled className="text-grayscale-48 hover:text-grayscale-100" />
            </div>
          )}
        </PrimitiveTooltip.Trigger>
        <PrimitiveTooltip.Content side={side ?? 'right'} align="center" style={{ margin, zIndex: 1000 }}>
          {arrow ?? <PrimitiveTooltip.Arrow offset={8} width={18} height={8} style={{ fill: grayscale[100] }} />}
          <Tooltip content={content} />
        </PrimitiveTooltip.Content>
      </PrimitiveTooltip.Root>
    </PrimitiveTooltip.Provider>
  );
};

export default EnhancedTooltip;
