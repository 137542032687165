const colors = require("@benepass/colors/src");
const fonts = require("@benepass/fonts");
const tailwindColors = require("tailwindcss/colors");

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
    "../../packages/**/*.{js,jsx,ts,tsx}",
  ],
  theme: {
    colors: {
      transparent: "transparent",
      current: "currentColor",
      black: tailwindColors.black,
      white: tailwindColors.white,
      indigo: colors.indigo,
      yellow: colors.yellow,
    },
    ...fonts,
    extend: {
      boxShadow: {
        "0dp":
          "0px 1px 1px rgba(0, 0, 0, 0.01), 0px 1px 1px rgba(0, 0, 0, 0.01)",
        "1dp":
          "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)",
        "1dp-inverted":
          "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)",
        "2dp":
          "0px 2px 7px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.05)",
        "3dp":
          "0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)",
        "4dp":
          "0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04)",
      },
      colors: {
        ...colors,
        input: {
          placeholder: "#C1C1C6",
          "bg-color": "#F5F5F5",
          color: "#161D2E",
        },
      },
      height: {
        "fill-available": "-webkit-fill-available",
        max: "max-content",
        fit: "fit-content",
      },
      maxHeight: {
        "90vh": "90vh",
      },
      minHeight: {
        0: "0",
        40: "10rem",
        80: "20rem",
        full: "100%",
        "fill-available": "-webkit-fill-available",
      },
      letterSpacing: {
        tightest: "-.075em",
        tighter: "-.05em",
        tight: "-.025em",
        normal: "0",
        wide: ".025em",
        wider: ".07em",
        widest: ".1em",
      },
      margin: {
        full: "100%",
        auto: "auto",
      },
      fontSize: {
        "display-1": ["40px", { lineHeight: "48px" }],
        "display-2": ["32px", { lineHeight: "40px" }],
        "display-3": ["28px", { lineHeight: "32px" }],
        "display-4": ["26px", { lineHeight: "32px" }],
        "display-5": ["26px", { lineHeight: "32px" }],
        "header-1": ["20px", { lineHeight: "32px" }],
        "header-2": ["18px", { lineHeight: "24px" }],
        "header-3": ["16px", { lineHeight: "24px" }],
        "header-4": ["16px", { lineHeight: "24px", letterSpacing: ".07em" }],
        "header-5": ["14px", { lineHeight: "20px" }],
        "header-6": ["14px", { lineHeight: "20px", letterSpacing: ".07em" }],
        "header-7": ["12px", { lineHeight: "16px" }],
      },
    },
  },
  variants: {
    extend: {
      margin: ["first", "last"],
      textColor: ["disabled"],
      borderWidth: ["last"],
      borderColor: ["disabled", "last"],
      backgroundColor: ["disabled"],
      backgroundOpacity: ["disabled"],
      boxShadow: ["hover", "disabled"],
      cursor: ["disabled"],
      opacity: ["disabled"],
    },
  },
  plugins: [],
};
