import styled from 'styled-components';
import tw from 'twin.macro';

import BenepassLogo from './components/logo';

const StyledOverlay = styled.div`
  ${tw`fixed inset-0 z-50 bg-white bg-opacity-90`}
  ${tw`flex items-center justify-center flex-col`}
  transform: translateZ(0px);
`;
const StyledLogo = styled(BenepassLogo)`
  ${tw`animate-bounce`}
`;
const StyledMessage = styled.p`
  ${tw`mt-12 text-grayscale-100 text-lg pl-4`}
`;

type Props = {
  message: string;
};

const OverlayLoader = ({ message }: Props) => (
  <StyledOverlay>
    <StyledLogo variant="full" size="md" />
    <StyledMessage>{message}</StyledMessage>
  </StyledOverlay>
);

export default OverlayLoader;
