import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { down } from 'styled-breakpoints';
import Logo from './components/logo';

const StyledLogo = styled(Logo)`
  ${tw`absolute`}
  top: 46px;
  left: 50%;
  transform: translateX(-50%);

  ${down('sm')} {
    & {
      ${tw`hidden`}
    }
  }
`;

const LayoutLoginContainer = ({ children }: React.PropsWithChildren) => (
  <main className="bg-grayscale-8 flex items-center justify-center h-screen relative">
    <StyledLogo variant="full" />
    {children}
  </main>
);

export default LayoutLoginContainer;
